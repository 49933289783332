import * as React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

function Selectbox(props) {
  const { label, name, options, error, helperText, required, ...rest } = props;

  return (
    <FormControl variant="standard" size="small" fullWidth error={error} required={required}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-label`}
        id={name}
        name={name}
        label={label}
        {...rest}
      >
        <MenuItem value="">--Select--</MenuItem>
        {options?.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default Selectbox;
