import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormikControl from "../FormikControl";
import { secureRequest, objectToFormData } from "../utils/axios-utils";
import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { handleToastMsg, toggleLoader } from "../slices/CommonSlice";
import useQueryParams from "../hooks/useQueryParams";
import useSwitchRoute from "../hooks/useSwitchRoute";
import { useAreasData, useSingleAreaData } from "../hooks/useAreaData";
import { useNewspapersData } from "../hooks/useNewspaperData";

import { Container, Box, AppBar, Tabs, Tab } from "@mui/material";
import { AppHeader, AppFooter } from "../components";

import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";

import { AccountCircleIcon, NewspaperIcon } from "../constants";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function AgentForm() {
  const queryParams = useQueryParams();
  const switchRoute = useSwitchRoute();
  const dispatch = useDispatch();

  const onSuccess = (data) => {
    // console.log(`Perform side effect after data fetching`, data);
  };

  const onError = (error) => {
    // console.log(`Perform side effect after encountering error`, error);
  };

  const {
    isLoading: isNewspaperLoading,
    isFetching: isNewspaperFetching,
    data: newspaperData,
    isError: isNewspaperError,
    error: Newspapererror,
  } = useNewspapersData(onSuccess, onError);

  const {
    isLoading: isAreaLoading,
    isFetching: isAreaFetching,
    data: areaData,
    isError: isAreaError,
    error: Areaerror,
  } = useAreasData(onSuccess, onError);

  useEffect(() => {
    dispatch(
      toggleLoader({
        loaderstatus:
          isNewspaperLoading ||
          isNewspaperFetching ||
          isAreaLoading ||
          isAreaFetching,
      })
    );
  }, [isNewspaperLoading, isNewspaperFetching, isAreaLoading, isAreaFetching]);

  const excludedKeys = [];
  const getVars = queryParams(excludedKeys);

  const { querystring, queryobject } = getVars;

  const editData = {};

  let mode = "Add";
  if (queryobject.mode === `edit`) {
    mode = "Edit";
  }

  const {
    isLoading: isEditLoading,
    data,
    dataUpdatedAt,
    isError,
    error,
  } = useSingleAreaData(queryobject.id);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is Required"),
    area: Yup.string().required("Area is Required"),
    address: Yup.string().required("Address is Required"),
    phone: Yup.string().required("Phone is Required"),
    // dob: Yup.string().required("Date of Birth is Required"),
    // place: Yup.string().required("Place is Required"),
    openingbalance: Yup.string().required("Opening Balance is Required"),
  });

  const initialValues = {
    name: "",
    area: "",
    address: "",
    phone: "",
    dob: "",
    place: "",
    openingbalance: "",
    status: false,
  };

  const mutation = useMutation((userData) =>
    secureRequest({ url: `/agent.php`, method: `POST`, data: userData }).then(
      (response) => response
    )
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const { status } = values;
      const reqObject = {
        Mode: `${mode}Agent`,
        status: Number(status),
        ...values,
        recordid: queryobject.id ?? "",
      };
      const formData = objectToFormData(reqObject);

      mutation.mutate(formData, {
        onSuccess: (res) => {
          const { data } = res;
          const { success, msg } = data;

          dispatch(handleToastMsg({ toaststatus: true, toastmsg: msg }));

          if (success) {
            if (queryobject.mode === `edit`) {
              switchRoute(`/areas`, true);
            } else {
              formik.resetForm();
            }
          }
        },
        onError: (res) => {
          console.log("Error=>", res);
        },
      });
    },
  });

  const { isLoading, /*isError,*/ isSuccess, data: resdata } = mutation;

  useEffect(() => {
    dispatch(toggleLoader({ loaderstatus: isLoading }));
  }, [isLoading]);

  useEffect(() => {
    if (data?.data?.success) {
      const areaDetail = data.data.areadetail;

      if (areaDetail) {
        const {
          name,
          area,
          address,
          phone,
          dob,
          place,
          openingbalance,
          status,
        } = areaDetail;

        formik.setValues({
          name: name || "",
          area: area || "",
          address: address || "",
          phone: phone || "",
          dob: dob || "",
          place: place || "",
          openingbalance: openingbalance || "",
          status: Number(status) === 1,
        });
      }
    }
  }, [data]);

  const theme = useTheme();

  const [activetab, setActiveTab] = useState(0);

  const handleActiveTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleChangeIndex = (index) => {
    setActiveTab(index);
  };

  return (
    <>
      <AppHeader spacing={0} applyshadow={false}>
        {mode} Agent
      </AppHeader>
      <form onSubmit={formik.handleSubmit} noValidate>
        <AppBar
          position="fixed"
          sx={{
            background: `#F7F7F8`,
            boxShadow: `0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)`,
            color: `#000000`,
            borderTop: `1px solid #d0d0d0`,
            top: `57px`,
          }}
        >
          <Tabs
            value={activetab}
            onChange={handleActiveTab}
            textColor="inherit"
            variant="fullWidth"
            aria-label="form tabs"
          >
            <Tab
              icon={<AccountCircleIcon />}
              label="Profile"
              {...a11yProps(0)}
              sx={{ fontSize: `12px`, p: 0.5, m: 0, minHeight: `65px` }}
            />
            <Tab
              icon={<NewspaperIcon />}
              label="Newspaper Comm."
              {...a11yProps(1)}
              sx={{ fontSize: `12px`, p: 0.5, m: 0, minHeight: `65px` }}
            />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activetab}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={activetab} index={0} dir={theme.direction}>
            <Container maxWidth="lg" sx={{ mb: 10, mt: 5 }}>
              <FormikControl
                control="input"
                type="text"
                label="Name of Agent"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                required
              />
              <FormikControl
                control="select"
                type="select"
                label="Select Area"
                name="area"
                value={formik.values.area}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.area && Boolean(formik.errors.area)}
                helperText={formik.touched.area && formik.errors.area}
                options={areaData?.data?.recordlist}
                required
              />
              <FormikControl
                control="input"
                type="text"
                label="Address"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
                required
              />
              <FormikControl
                control="phone"
                type="text"
                label="Phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
                required
              />
              <FormikControl
                control="date"
                type="text"
                label="Date of Birth"
                name="dob"
                value={formik.values.dob}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.dob && Boolean(formik.errors.dob)}
                helperText={formik.touched.dob && formik.errors.dob}
              />
              <FormikControl
                control="input"
                type="text"
                label="Place"
                name="place"
                value={formik.values.place}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.place && Boolean(formik.errors.place)}
                helperText={formik.touched.place && formik.errors.place}
              />
              <FormikControl
                control="input"
                type="text"
                label="Opening Balance"
                name="openingbalance"
                value={formik.values.openingbalance}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.openingbalance &&
                  Boolean(formik.errors.openingbalance)
                }
                helperText={
                  formik.touched.openingbalance && formik.errors.openingbalance
                }
              />
              <FormikControl
                control="toggle"
                label="Status"
                name="status"
                checked={formik.values.status}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Container>
          </TabPanel>
          <TabPanel value={activetab} index={1} dir={theme.direction}>
            <Container maxWidth="lg" sx={{ mb: 10, mt: 10 }}>
              {newspaperData?.data?.recordlist?.map((item, index) => {
                return <div key={item.id}>{item.name}</div>;
              })}
              Item Two
            </Container>
          </TabPanel>
        </SwipeableViews>
      </form>
      <AppFooter onClick={formik.handleSubmit} />
    </>
  );
}

export default AgentForm;
